import React, { useRef, useState } from "react";
import Fade from "react-reveal/Fade";
import SendArrow from "../../assets/images/homepage/sendarrow.svg";
import emailjs from "@emailjs/browser";

export default function Contact() {
  // const form = useRef();
  // let initialState  = {
  // 	user_name: "",
  // 	user_email: "",
  // 	message: ""
  // };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    const serviceId = "service_qoh4349";
    const templateId = "template_nvfzhcb";
    const publicKey = "aPfJTz6gCH1CDJv5a";

    const templateParams = {
      user_name: name,
      user_email: email,
      message: message,
    };

    // const [form, setForm] = useState(initialState);
    // const onChange = (e) => {
    // 	console.log("hhh");
    // 	setForm({
    // 	  ...form,
    // 	  [e.target.name]: e.target.value,
    // 	});
    //   };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log(response);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   console.log(form)
  //   emailjs.sendForm('service_qoh4349', 'template_nvfzhcb', JSON.stringify(form), 'aPfJTz6gCH1CDJv5a')
  // 	.then((result) => {
  // 		console.log(result.text);
  // 	}, (error) => {
  // 		console.log(error.text);
  // 	});
  // };
  return (
    <Fade>
      <div
        id="contact-us"
        className="grid grid-cols-1 px-6 mb-6 text-sm mt-40 md:mb-12 lg:text-base lg:px-28 xl:px-44 relative xl:max-w-screen-xl mx-auto"
        style={{
          background: "linear-gradient(white, #EDC978)",
          borderRadius: "7em / 5em",
        }}
      >
        <p className="font-light mb-1 text-sm" style={{ color: "#EDC978" }}>
          We're always glad to help
        </p>
        <h2
          className="text-4xl text-darkBlue font-bold border-b-4 w-fit border-lightBlue mb-20 lg:mb-28"
          style={{ color: "#B7894F", borderColor: "#B7894F" }}
        >
          Contact Us
        </h2>
        <Fade cascade bottom>
          <form
            onSubmit={sendEmail}
            className="flex flex-col gap-10 mb-12 md:flex-row md:flex-wrap md:px-14 md:gap-x-28 lg:px-0"
          >
            <input
              className="border-b pb-3 grow border-0 border-inherit"
              type="text"
              placeholder="Your name"
              required
              name="user_name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              style={{ borderRadius: "10px" }}
            ></input>
            <input
              className="border-b pb-3 grow border-0 border-inherit"
              type="email"
              placeholder="Your email"
              required
              name="user_email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              style={{ borderRadius: "10px" }}
            ></input>
            <textarea
              className="border-b w-full mt-7 border-0 border-inherit"
              type="text"
              placeholder="Your Message"
              required
              name="message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              style={{ borderRadius: "10px" }}
            ></textarea>
            <button
              className="bg-darkBlue text-white transition duration-200 hover:bg-sky-300 text-sm rounded-full w-fit flex gap-3 items-center py-1 pl-3 pr-1 mt-6 mx-auto"
              type="submit"
              value="Send"
            >
              <span className="pl-3 mt-1">Send</span>
              <img
                className="bg-white rounded-full h-7 w-7"
                src={SendArrow}
                alt="arrow"
              ></img>
            </button>
          </form>
          {/* <form onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" onChange={(e)=>setName(e.target.value)}/>
      <label>Email</label>
      <input type="email" name="user_email" onChange={(e)=>setEmail(e.target.value)} />
      <label>Message</label>
      <textarea name="message" onChange={(e)=>setMessage(e.target.value)} />
      <input type="submit" value="Send" />
    </form> */}
        </Fade>
        <div
          id="validate"
          className="absolute text-green-600 mx-auto bottom-2 left-0 right-0 w-fit hidden"
        >
          Message successfully sent!
        </div>
      </div>
    </Fade>
  );
}
