// import ExpertiseCard from "../../components/Home/ExpertiseCard";
import Fade from "react-reveal/Fade";
// import { medExcellence } from "../../data/medExcellence";
// import ExcellenceCard from "./ExcellenceCard";
import { OgServicesData } from "../../../data/OgServicesData";
import OgCard from "./OgCard";

export default function OgServices() {
  return (
    <Fade>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p
          className="absolute font-bold text-4xl leading-8 mt-12 md:text-2xl lg:text-1xl ogparagraph"
          style={{
            width: "87%",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "300",
            color: "black",
            marginTop: "0",
            fontSize: "1rem",
            lineHeight: "1.5rem",
          }}
        >
          Welcome to our Oil and Gas Department! Step into the refined domain of
          our illustrious Oil and Gas Equipment Division, our realm is
          meticulously tailored to provide you with a curated selection of
          cutting-edge equipment, premium supplies, and sophisticated chemical
          dosing solutions, all underscored by our comprehensive AMC (Annual
          Maintenance Contract) offerings. With our headquarters situated in
          Qatar and a strong operational presence in the Kingdom of Saudi Arabia
          and the Sultanate of Oman, beckoning you to embark on an extraordinary
          voyage owards innovation and partnership.
        </p>
        <div
          className="grid grid-cols-1 gap-16 w-md px-6 lg:px-10 mx-auto max-w-screen-xl"
          style={{ marginTop: "11%" }}
        >
          <h2
            className="text-4xl text-darkBlue font-bold w-fit border-lightBlue"
            style={{
              color: "#EDC978",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            OUR SERVICES
          </h2>

          <div
            className="container px-2 md:pl-20 md:mt-8 lg:pl-0 md:grid block grid-cols-3 max-w-3xl mx-auto gap-10"
            style={{ maxWidth: "84rem" }}
          >
            {OgServicesData.map((item) => {
              return OgCard(item);
            })}
          </div>
        </div>
      </div>
    </Fade>
  );
}
