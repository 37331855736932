import ExpertiseCard from "../../components/Home/ExpertiseCard";
import Fade from "react-reveal/Fade";
import { medExcellence } from "../../data/medExcellence";
import ExcellenceCard from "./ExcellenceCard";

export default function HealthExcellence() {
  return (
    <Fade>
      <div className="grid grid-cols-1 gap-16 w-md px-6 lg:px-10 mx-auto max-w-screen-xl">
        <h2
          className="text-4xl text-darkBlue font-bold w-fit"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          Medical Turnkey Excellence
        </h2>

        <div
          className="container px-2 md:pl-20 md:mt-8 lg:pl-0 md:grid block grid-cols-3 mx-auto gap-10"
          style={{ maxWidth: "84rem" }}
        >
          {medExcellence.map((item) => {
            return ExcellenceCard(item);
          })}
        </div>
      </div>
    </Fade>
  );
}
