const products = [
  {
    id: 1,
    name: "Personal Protective Equipment",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/worker-wearing-safety-glasses-construction-site_23-2148784073.jpg?w=1060&t=st=1708162591~exp=1708163191~hmac=2d6f625fe98e4d7d9145e0dda572f99ddaea3b65e526a9d88ac01c443480d5dd",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Face Mask",
      "Eye Protector",
      "Face Shield",
      "Forehead Infrared Thermometer",
      "Disposable Coverall",
    ],
  },
  {
    id: 2,
    name: "Wound Care Treatment & Management",
    role: "Web Developer, QA Lead",
    paragraph:
      "Malcolm Tan is an aspiring front-end developer based in Vancouver with a multi-media skillset in UX/UI design, brand-recognition, photography and video-editing.",
    fullBio:
      "Malcolm Tan is an aspiring front-end developer based in Vancouver with a multi-media skillset in UX/UI design, brand-recognition, photography and video-editing. He is studying at the British Institute of Technology for New Media Design and Web Development and also has a foundational background in business management and marketing.",
    imagePath:
      "https://img.freepik.com/free-photo/close-up-doctor-bandaging-arm_23-2148231330.jpg?w=1060&t=st=1708165766~exp=1708166366~hmac=95326a10f87cbeada3c62f5675219fc36222d4948bfd5996317924e025e717e2",
    imageName: "Profile Picture of Malcolm Tan",
    projectName: "Flyo Landing Page",
    projectDesc: "Check out Malcolm's latest project!",
    projectImagePath: "assets/images/profilepage/wound.png",
    projectImageName: "Screenshot of Project",
    projectPath: "https://malcolm-tan.com/projects/flyo.php",
    portfolioSite: "https://malcolm-tan.com/",
    portfolioName: "Visit Malcolm's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/malcolmtan-/",
    LinkedInName: "Visit Malcolm's LinkedIn",
    otherSite: "https://github.com/MalcolmTann",
    otherSiteName: "Visit Malcolm's GitHub",
    productslist: [
      "Dressings",
      "Antiseptics and Cleansers",
      "Topical Ointments and Creams",
      "Compression Bandages and Wraps",
      "Negative Pressure Wound Therapy (NPWT)",
      "Wound Closure Devices",
      "Wound Syringes and irrigation solutions",
      "Absorbent Pads and Underpass",
      "Skin Protectants",
    ],
  },
  {
    id: 3,
    name: "Human Medical Anatomical Model",
    role: "Web Developer, React Developer",
    paragraph:
      "Jessica Lee is an aspiring Front-End/Web Developer in Vancouver, British Columbia, with a background in English Literature and Creative Writing and a passion for stories.",
    fullBio:
      "Jessica Lee is an aspiring Front-End/Web Developer in Vancouver, British Columbia, with a background in English Literature and Creative Writing and a passion for analyzing and sharing immersive stories and experiences.",
    imagePath:
      "https://img.freepik.com/free-photo/grunge-style-medical-figure-background_1048-10210.jpg?w=900&t=st=1708168685~exp=1708169285~hmac=1d9677de998e47a312b55d034c3002ba0c407b69d368ff59924d91392a60340a",
    imageName: "Profile Picture of Jessica Lee",
    projectName: "LOCLO",
    projectDesc: "Check out Jessica's latest project!",
    projectImagePath: "assets/images/profilepage/loclo.jpg",
    projectImageName: "Screenshot of Project",
    projectPath: "https://jessicamlee.dev/projects/locloclothing",
    portfolioSite: "https://jessicamlee.dev/",
    portfolioName: "Visit Jessica's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/jessicamlee-/",
    LinkedInName: "Visit Jessica's LinkedIn",
    otherSite: "https://github.com/jessicamlee",
    otherSiteName: "Visit Jessica's GitHub",
    productslist: [
      "CPR and Basic Life Support (BLS) Manikins",
      "Nursing and Patient Care Manikins",
      "Obstetric and Gynaecological Manikins",
      "Paediatric Manikins",
      "Surgical Skills Training Manikins",
      "Anatomical Models and Manikins",
      "Emergency Skills Training",
      "Clinical Skills Training",
      "Diagnosis Skills Training",
    ],
  },
  {
    id: 4,
    name: "Operating Theatre Equipment",
    role: "Web Developer, React Developer",
    paragraph:
      "As an enthusiastic and aspiring Front-End developer, Samantha is eager to expand her skills and broaden her professional network.",
    fullBio:
      "As an enthusiastic and aspiring Front-End developer, Samantha began her studies at BCIT in the New Media and Web Development program, focusing on Frontend development (Javascript, PHP, HTML,CSS) along with UX/UI concepts. Samantha is eager to expand her skills and broaden her professional network.",
    imagePath:
      "https://img.freepik.com/free-photo/interior-view-operating-room_1170-2254.jpg?w=1060&t=st=1708168734~exp=1708169334~hmac=1062303406dc969ccca46355532d4ee5145e734e04fe85bd4d128754eed33240",
    imageName: "Profile Picture of Samantha Tan",
    projectName: "Konasofa",
    projectDesc: "Check out Samantha's latest project!",
    projectImagePath: "assets/images/profilepage/konasofa.png",
    projectImageName: "Screenshot of Project",
    projectPath: "https://github.com/samantha-t28",
    portfolioSite: "https://samanthatan.ca/",
    portfolioName: "Visit Samantha's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/samantha-tan2/",
    LinkedInName: "Visit Samantha's LinkedIn",
    otherSite: "https://github.com/samantha-t28",
    otherSiteName: "Visit Samantha's GitHub",
    productslist: [
      "Shockwave Lithotripter",
      "Anaesthesia Machine",
      "Ventilator",
      "Defibrillator",
      "Electrosurgical Unit",
      "Patient Monitor",
      "Medical Pendant",
      "Operating Lamp",
      "LED Operating Lamp",
      "Operating Table",
      "Delivery Table",
      "Blood Infusion Warmer",
      "Suction Unit",
      "Syringe Pump",
      "Infusion Pump",
      "Feeding Pump",
      "Operating Microscope",
      "Surgical Tools",
    ],
  },
  {
    id: 43,
    name: "Operating Theatre Equipment",
    role: "Web Developer, React Developer",
    paragraph:
      "As an enthusiastic and aspiring Front-End developer, Samantha is eager to expand her skills and broaden her professional network.",
    fullBio:
      "As an enthusiastic and aspiring Front-End developer, Samantha began her studies at BCIT in the New Media and Web Development program, focusing on Frontend development (Javascript, PHP, HTML,CSS) along with UX/UI concepts. Samantha is eager to expand her skills and broaden her professional network.",
    imagePath:
      "https://img.freepik.com/free-photo/interior-view-operating-room_1170-2254.jpg?w=1060&t=st=1708168734~exp=1708169334~hmac=1062303406dc969ccca46355532d4ee5145e734e04fe85bd4d128754eed33240",
    imageName: "Profile Picture of Samantha Tan",
    projectName: "Konasofa",
    projectDesc: "Check out Samantha's latest project!",
    projectImagePath: "assets/images/profilepage/konasofa.png",
    projectImageName: "Screenshot of Project",
    projectPath: "https://github.com/samantha-t28",
    portfolioSite: "https://samanthatan.ca/",
    portfolioName: "Visit Samantha's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/samantha-tan2/",
    LinkedInName: "Visit Samantha's LinkedIn",
    otherSite: "https://github.com/samantha-t28",
    otherSiteName: "Visit Samantha's GitHub",
    productslist: [
      "Delivery Table",
      "Blood Infusion Warmer",
      "Suction Unit",
      "Syringe Pump",
      "Infusion Pump",
      "Feeding Pump",
      "Operating Microscope",
      "Surgical Tools",
    ],
  },
  {
    id: 5,
    name: "OB/GYN Equipment",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/gynecologist-performing-ultrasound-consultation_23-2149353027.jpg?w=1060&t=st=1708168834~exp=1708169434~hmac=b1e4fdc4a6e8f1ec936e213c809baf67f7a52f29f7a44ec0eb325c278e438426",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Fetal Doppler",
      "Fetal Monitor",
      "Colposcope",
      "Gynaecological Bed",
      "Infant Incubator",
      "Infant Radiant Warmer",
      "Infant Phototherapy unit",
      "Jaundice Meter",
      "Mammography X-ray System",
      "Infant Scales",
    ],
  },
  {
    id: 6,
    name: "Diagnostic Equipment",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/close-up-heart-rate-monitor-measuring-heartbeat-patient-doing-gymnastics-physical-recovery-retired-man-using-sport-equipment-exercise-physiotherapy-strength_482257-30692.jpg?w=1060&t=st=1708168989~exp=1708169589~hmac=ba38e6f68ae491f7eadf49c577600bdb8d5529c88b9499a3b95c15a18fdecc79",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Ultrasound Scanner",
      "ECG Machine",
      "Holter ECG & Stress ECG",
      "EEG",
      "Pulse Oximeter",
      "Spirometer",
      "Audiometer",
      "Hand-Held ETCO2",
      "Blood Glucose Monitor",
      "Vein Finder",
      "Stethoscope",
    ],
  },
  {
    id: 7,
    name: "Laboratory Equipment",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/scientific-microscope-laboratory-desk-with-researching-instruments_482257-13971.jpg?w=1380&t=st=1708169032~exp=1708169632~hmac=eb217f0a5990ce64f1330b731c36cd3c51a1a11b5e2ea0ef73c29933709b4ec2",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Biological Microscope",
      "Spectrophotometer",
      "High/low Speed Centrifuge",
      "High/low Speed Freeze Centrifuge",
      "Laboratory Shaker",
      "Laboratory Mixer",
      "Water Distiller for main analysers",
      "Laboratory Incubator",
      "PH Meter",
      "Biological Safety Cabinet",
      "Laminar Flow Cabinet",
      "Ultrasonic Cleaner",
      "Medical Hand Sink",
      "Medical Freezer",
      "Blood Bank Refrigerator",
      "Medical Pharmacy Refrigerator",
    ],
  },
  {
    id: 73,
    name: "Laboratory Equipment",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/scientific-microscope-laboratory-desk-with-researching-instruments_482257-13971.jpg?w=1380&t=st=1708169032~exp=1708169632~hmac=eb217f0a5990ce64f1330b731c36cd3c51a1a11b5e2ea0ef73c29933709b4ec2",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Ultrasonic Cleaner",
      "Medical Hand Sink",
      "Medical Freezer",
      "Blood Bank Refrigerator",
      "Medical Pharmacy Refrigerator",
    ],
  },
  {
    id: 8,
    name: "Medical Consumables",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/high-angle-pill-foils-plastic-containers_23-2148533456.jpg?w=1060&t=st=1708169129~exp=1708169729~hmac=54e73dc4d5faf92b6cccae7125f5fdfbc5e97db095fa1ea24bb04cfe42ff4320",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Surgical Staplers",
      "Surgical Sutures",
      "ESU Consumables",
      "Medical Mask",
      "Disposable Syringe",
      "Medical Cannula",
      "Medical Gloves",
      "Sterilization Pouch",
    ],
  },
  {
    id: 9,
    name: "X-ray Series",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/doctor-looking-ct-scan_23-2149367428.jpg?w=1060&t=st=1708169173~exp=1708169773~hmac=4228082f7b5e0664bfd361d838c3a4c03a6b98d0af3b197f82d5ddcdf28f3ce8",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "DR X-ray System",
      "C-arm X-ray System",
      "Mobile X-ray Machine",
      "Radiography X-ray System",
      "Mammography X-ray System",
      "Dental X-ray Machine",
      "Portable X-ray Machine",
      "X-ray Film Illuminator",
      "X-ray Protective Products",
    ],
  },
  {
    id: 10,
    name: "Hospital Furniture",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/clean-empty-hospital-ward-ready-receive-patients-reflecting-modern-medical-care_91128-4608.jpg?w=1380&t=st=1708169246~exp=1708169846~hmac=558884e5b2cea431a435e26979301639702a7cfc547743822ffb7680d84f1e6d",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Manual Hospital Bed",
      "Electric Hospital Bed",
      "ICU Bed",
      "Child Bed",
      "Medical Trolley",
      "Chairs",
      "Bedside Cabinet",
      "Appliance Cupboard",
      "Examination Couch",
      "Overbed Table",
      "Ward Equipment",
    ],
  },
  {
    id: 11,
    name: "Walking Aids",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/child-helping-his-disabled-mother_23-2149226794.jpg?w=1060&t=st=1708169337~exp=1708169937~hmac=145ad640280bb147ab29cef3ff3a07c281a0b05f0d5c6e4a5358c3060fa648ca",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Crutches and Canes",
      "Walkers",
      "Rollators",
      "Bath Bench",
      "Commode Seat",
      "Commode Wheelchair",
      "Steel Wheelchair",
      "Aluminium Wheelchair",
    ],
  },
  {
    id: 12,
    name: "First-Aid Products",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-vector/healthcare-medications-flat-background_1284-73978.jpg?w=900&t=st=1708169436~exp=1708170036~hmac=03f0b3e6278ca3215a128a9b31e44c744953b9608a09474f9cde132c0d88d7b9",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Defibrillator",
      "Portable ventilator",
      "Spine Board",
      "Ambulance Stretcher",
      "Scoop Stretcher",
      "Folding Stretcher",
      "Vacuum Stretcher",
      "Basket Stretcher",
      "Resuscitator",
      "First aid bag",
      "Stretcher Base",
    ],
  },
  {
    id: 13,
    name: "Dental Equipment",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/empty-modern-teethcare-stomatology-hospital-office-with-nobody-it-equipped-with-dental-intruments-ready-orthodontist-healthcare-treatment-tooth-radiography-images-display_482257-9418.jpg?w=1060&t=st=1708169509~exp=1708170109~hmac=6557f1b31bc7839b6bc912f485fb0ee8deb3c07c9f989394cb39b5e196f00cf5",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Dental Unit",
      "Dental Hand Instruments",
      "Dental Restorative Materials",
      "Dental Handpieces",
      "Dental Prosthetics",
      "Dental Preventive Products",
      "Dental Sterilization and Infection Control",
      "Dental Endodontic Materials",
      "Dental Orthodontic Supplies",
      "Dental Surgical Instruments ",
      "Dental Disposable Supplies",
      "Dental X-ray Equipment and Supplies",
    ],
  },
  {
    id: 14,
    name: "Medical Consumables",
    role: "Graphic Designer, QA Tester",
    paragraph:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to production in her graphic design work.",
    fullBio:
      "Hannah is on her way to share her graphic design skills with the world from the ideation to prototyping phase. Hannah plans to continue to sharing her artistic perspective by bringing her creativity in her graphic design work.",
    imagePath:
      "https://img.freepik.com/free-photo/high-angle-pill-foils-plastic-containers_23-2148533456.jpg?w=1060&t=st=1708169129~exp=1708169729~hmac=54e73dc4d5faf92b6cccae7125f5fdfbc5e97db095fa1ea24bb04cfe42ff4320",
    imageName: "Profile Picture of Hannah Smith",
    projectName: "Project Name",
    projectDesc: "Check out Hannah's latest project!",
    projectImagePath: "assets/images/profilepage/screenshot.jpg",
    projectImageName: "Screenshot of Project",
    portfolioSite: "https://portfolio/hlsmith.online",
    portfolioName: "Visit Hannah's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/hannah-smith-243a67233/",
    LinkedInName: "Visit Hannah's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Surgical Staplers",
      "Surgical Sutures",
      "ESU Consumables",
      "Medical Mask",
      "Disposable Syringe",
      "Medical Cannula",
      "Medical Gloves",
      "Sterilization Pouch",
    ],
  },
  {
    id: 15,
    name: "POC Testing Devices",
    role: "Digital Designer, UX/UI Designer",
    paragraph:
      "Cindy’s unique styles and designs aim to attract and fulfill the specific needs of her clients. For each client, she strives to understand them on a personal level.",
    fullBio:
      "Cindy Shin is currently in the last term of her studies in New Media Design and Web Development at the British Columbia Institute of Technology. She hopes to utilize her learned skills in fulfilling her dreams as an aspiring Graphic and UX/UI designer.",
    imagePath:
      "https://img.freepik.com/free-photo/doctors-wearing-protective-medical-equipment_23-2148837596.jpg?w=1060&t=st=1708169623~exp=1708170223~hmac=7827e6af2eaa74b29850f5f529ef9db7b3799d0cc20ef746a816d9d986a570a6",
    imageName: "Profile Picture of Cindy Shin",
    projectName: "Do Your Work",
    projectDesc: "Check out Cindy's latest project!",
    projectImagePath: "assets/images/profilepage/dyw.jpg",
    projectImageName: "Screenshot of Project",
    projectPath: "https://cinshin.com/",
    portfolioSite: "https://cinshin.com/",
    portfolioName: "Visit Cindy's Portfolio",
    emailAddress: "#",
    emailName: "#",
    LinkedInPage: "https://www.linkedin.com/in/cinshin/",
    LinkedInName: "Visit Cindy's LinkedIn",
    otherSite: "#",
    otherSiteName: "#",
    productslist: [
      "Face Mask",
      "Eye Protector",
      "Face Shield",
      "Forehead Infrared Thermometer",
      "Disposable Coverall",
    ],
  },
];

export { products };
