const dataExpertise = [
	{
		id: 1,
		title: "Healthcare",
		paragraph:
			"At AMEX, we believe in shaping a brighter future for healthcare, where cutting-edge technology, dedicated professionals, and unwavering commitment converge to redefine the landscape of well-being. Our journey is defined by a relentless pursuit of excellence, as we stand proudly at the forefront of healthcare advancement.",
		imagePath: "assets/images/icons/healthIcon.png",
		imageName: "Web browser with light bulb",
	},
	{
		id: 2,
		title: "Oil and gas",
		paragraph:
			"In the realm of Oil & Gas, AMEX INTERNATIONAL stands as a steadfast partner, offering a comprehensive spectrum of services that drive efficiency, safety, and sustainable growth. Our expertise in this critical sector empowers us to navigate complexities and deliver solutions that power progress.",
		imagePath: "assets/images/icons/oilGas.png",
		imageName: "Web browser with closing code tag icon",
	},
	{
		id: 3,
		title: "Engineering and facility management",
		paragraph:
			"Moreover, our influence extends to the Engineering and Facility Management domains, where we orchestrate seamless operations and elevate infrastructure management. With an unwavering focus on quality and reliability, we ensure that your facilities operate at their peak, allowing you to focus on your core objectives.",
		imagePath: "assets/images/icons/engineering.png",
		imageName: "Design tools icons",
	},
];

export { dataExpertise };