import { useState } from "react";
import Fade from "react-reveal/Fade";
import BurgerMenu from "../../assets/images/burger.svg";
import Logo from "../../assets/images/logo/amexLogo.png";
import Brochure from "../../assets/brochure/AMEX-Brochure.pdf";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useNavigate } from "react-router-dom";

export default function HeaderVideo() {
  const [navbar, setNavbar] = useState(false);
  const navigate = useNavigate();
  const showNavBg = () => {
    if (window.scrollY >= 576) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", showNavBg);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const openProducts = () => {
    localStorage.setItem("productOpen", true);
    navigate("/productList");
  };
  const openDivisions = () => {
    localStorage.setItem("divisionsOpen", true);
    navigate("/divisions");
  };
  const openTeam = () => {
    localStorage.setItem("teamOpen", true);
    navigate("/team");
  };
  const openAbout = () => {
    localStorage.setItem("aboutOpen", true);
    navigate("/about");
  };
  const openContact = () => {
    localStorage.setItem("contactOpen", true);
    navigate("/contact");
  };
  return (
    <header className="relative text-white p-6 top-0 z-1 -mt-24">
      <nav
        className={navbar ? "navbar active" : "navbar"}
        style={navbar ? { backgroundColor: "rgba(255,255,255,0.5)" } : null}
      >
        {/* {backgroundColor:"rgba(255,255,255,0.5)"} */}
        <Fade top>
          <h2 className="font-Overpass font-bold text-xl mt-1 lg:text-2xl xl:text-3xl transition duration-200 hover:text-sky-300">
            <a href="" title="Go to Home page">
              <img
                src={Logo}
                className="w-[5.5rem]  md:w-[10rem] lg:w-[10rem] xl:w-[10rem]"
                // style={{width:"15%"}}
              />
            </a>
          </h2>
        </Fade>

        {/* Mobile Menu */}
        <div className="flex lg:hidden">
          <Fade top>
            <img
              className="h-10 w-10"
              onClick={() => setIsNavOpen((prev) => !prev)}
              src={BurgerMenu}
              alt="menu"
            ></img>
          </Fade>
          <div className={isNavOpen ? "showNav" : "hideNav"}>
            <div
              className="absolute top-0 right-0 p-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-white fill-none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <Fade cascade>
              <ul className="nav-items-mobile">
                {/* <li>
									<a
										className="transition duration-200 hover:text-sky-300"
										href="https://jessicamlee.github.io/AMEX/"
										title="Home"
									>
										Home
									</a>
								</li> */}
                <li>
                  <a
                    className="transition duration-200 hover:text-sky-300"
                    href="#about"
                    title="About Us"
                    onClick={() => setIsNavOpen((prev) => !prev)}
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    className="transition duration-200 hover:text-sky-300"
                    title="Our Work"
                    href={Brochure}
                    download="AMEX-Brochure"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setIsNavOpen((prev) => !prev)}
                  >
                    Brochure
                  </a>
                </li>
                <li>
                  <a
                    className="transition duration-200 hover:text-sky-300"
                    href="#contact-us"
                    title="Contact Us"
                    onClick={() => setIsNavOpen((prev) => !prev)}
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    className="transition duration-200 hover:text-sky-300"
                    href="#contact-us"
                    title="Contact Us"
                    onClick={() => setIsNavOpen((prev) => !prev)}
                  >
                    Meet The Team
                  </a>
                </li>
              </ul>
            </Fade>
          </div>
        </div>

        {/* Desktop Menu */}
        <Fade top cascade>
          <ul className="nav-items-desktop" style={{ fontSize: "19px" }}>
            {/* <li style={{color:"black", fontFamily:"'Poppins', sans-serif", fontWeight:"600"}}>
							<a
								className="transition duration-200 hover:text-sky-300 hover:underline"
								href="http://Www.Amex-Intl.com"
								title="Home"
							>
								Home
							</a>
						</li> */}
            <li
              style={{
                color: "#EDC978",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "600",
              }}
            >
              <button
                className="transition duration-200 hover:text-sky-300 hover:underline"
                href="#about"
                title="About Us"
                onClick={openAbout}
              >
                About
              </button>
              {/* <a
                className="transition duration-200 hover:text-sky-300 hover:underline"
                href="#about"
                title="About Us"
              >
                About
              </a> */}
            </li>
            <li
              style={{
                color: "#EDC978",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "600",
              }}
            >
              <a
                className="transition duration-200 hover:text-sky-300 hover:underline"
                href={Brochure}
                download="AMEX-Brochure"
                target="_blank"
                rel="noreferrer"
              >
                Brochure
              </a>
            </li>
            <li
              style={{
                color: "#EDC978",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "600",
              }}
            >
              <button
                className="transition duration-200 hover:text-sky-300 hover:underline"
                href="#about"
                title="About Us"
                onClick={openContact}
              >
                Contact
              </button>
              {/* <a
                className="transition duration-200 hover:text-sky-300 hover:underline"
                href="#contact-us"
                title="Contact Us"
              >
                Contact
              </a> */}
              {/* <AnchorLink href="#contact-us">
							<button>Contact</button>
							</AnchorLink> */}
            </li>
            <li
              style={{
                color: "#EDC978",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "600",
              }}
            >
              <button
                className="transition duration-200 hover:text-sky-300 hover:underline"
                href="#about"
                title="About Us"
                onClick={openProducts}
              >
                Products
              </button>
            </li>
            <li
              style={{
                color: "#EDC978",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "600",
              }}
            >
              <button
                className="transition duration-200 hover:text-sky-300 hover:underline"
                href="#about"
                title="About Us"
                onClick={openDivisions}
              >
                Divisions
              </button>
            </li>
            <li
              style={{
                color: "#EDC978",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "600",
              }}
            >
              <button
                className="transition duration-200 hover:text-sky-300 hover:underline"
                href="#about"
                title="About Us"
                onClick={openTeam}
              >
                Meet The Team
              </button>
            </li>
          </ul>
        </Fade>
      </nav>
    </header>
  );
}
