import Fade from "react-reveal/Fade";
import "./style.css";

export default function MedEngCard({
  id,
  title,
  paragraph,
  imagePath,
  imageName,
}) {
  return (
    <Fade cascade>
      <div className="card">
        <div
          key={id}
          className="mb-10 text-darkBlue font-bold w-fit sm:pr-4 md:pr-12 lg:pl-20 lg:pr-0 card2"
          style={{
            color: "#B7894F",
            borderRadius: "30px",
            background: "rgb(247 244 238)",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 30px 50px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 26px -18px inset",
            padding: "2rem",
          }}
        >
          <div class="go-corner" href="#">
            <div class="go-arrow"></div>
          </div>
          <img
            className="hidden md:block"
            src={imagePath}
            alt={imageName}
            style={{ width: "30%" }}
          />
          <h3 className="font-bold mb-8 mt-4" style={{ color: "EDC978" }}>
            {title}
          </h3>
          <p className="text-sm font-light leading-6">{paragraph}</p>
        </div>
      </div>
    </Fade>
  );
}
