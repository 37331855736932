import ExpertiseCard from "../../components/Home/ExpertiseCard";
import Footer from "../../components/Navigation/Footer";
import HeaderPlain from "../../components/Navigation/HeaderPlain";
import { dataExpertise } from "../../data/Expertise";
import Fade from "react-reveal/Fade";

export default function Expertise() {
  return (
    <Fade>
      <div className="grid grid-cols-1 gap-16 w-md px-6 lg:px-10 mx-auto max-w-screen-xl">
        <HeaderPlain />
        <h2
          className="text-4xl text-darkBlue font-bold border-b-4 w-fit border-lightBlue"
          style={{
            borderColor: "#EDC978",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "500",
            color: "#EDC978",
            marginTop: "7%",
          }}
        >
          OUR
          <span
            style={{ fontFamily: "'Poppins', sans-serif", fontWeight: "700" }}
          >
            {" "}
            DIVISIONS
          </span>
        </h2>

        <div className="container px-2 md:pl-20 md:mt-8 lg:pl-0 md:grid block grid-cols-2 max-w-3xl mx-auto gap-10">
          {dataExpertise.map((item) => {
            return ExpertiseCard(item);
          })}
        </div>
      </div>
      <Footer />
    </Fade>
  );
}
