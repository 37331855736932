const OgServicesData = [
	{
		id: 1,
		title: "Our Comprehensive Spectrum of Specialized Solutions",
		paragraph:
			"At the zenith of our specialized expertise, we orchestrate an ensemble of solutions that elegantly span the entire spectrum of the Oil and Gas industry, artfully enriched by the integration of precise chemical dosing techniques. From trailblazing exploration tools that transcend conventional boundaries to catalytic processes that ingeniously optimize performance, we are your unwavering strategic partners, devoted to illuminating new horizons.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/5805/5805635.png?uid=R113815869&ga=GA1.1.782505387.1692784381",
		imageName: "Specialized Solutions",
	},
	{
		id: 2,
		title: "First-Class Equipment and Supplies, Unparalleled Service",
		paragraph:
			"Elevating the very concept of excellence, we lead the charge as your first choice for premium oil and gas equipment and accessories. Our carefully curated selection of equipment, sourced from the industry's paramount brands, ensures that your operations are fortified by the finest tools available. With our commitment to service support, we stand ready to provide an unparalleled partnership, where every equipment is backed by our unwavering dedication to your success",
		imagePath: "https://cdn-icons-png.flaticon.com/512/686/686379.png?uid=R113815869&ga=GA1.1.782505387.1692784381",
		imageName: " Unparalleled Service",
	},
	{
		id: 3,
		title: "Strategic Prowess in Exploration and Production",
		paragraph:
			"Our prowess ventures beyond the ordinary, offering strategic mastery in exploration and production. Cutting-edge drilling systems harness reservoir potential with finesse, while meticulously curated well completion techniques infuse hydrocarbon assets with vitality. Furthermore, our reservoir analysis solutions unravel the enigmatic subterranean tapestry, paving the way for strategic decision-making rooted in empirical data.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/2268/2268705.png?uid=R113815869&ga=GA1.1.782505387.1692784381",
		imageName: "Strategic icon",
	},
	{
		id: 4,
		title: "Revolutionizing Transportation, Storage, and Catalysis",
		paragraph:
			"Synthesizing fluidity and reliability, we orchestrate a revolution across the transportation and storage milieu, seamlessly interweaving the art of chemical dosing. Impermeable pipelines, fortified tankers, and avant-garde storage solutions converge harmoniously, fostering an uninterrupted flow of energy. Our catalytic processes further enrich the realm of refining and processing, where chemical dosing enhances efficacy to an elevated plane.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/568/568155.png?uid=R113815869&ga=GA1.1.782505387.1692784381",
		imageName: "Revolutionizing icon",
	},
    {
		id: 5,
		title: "Chemical Fusion in Distribution and Retail",
		paragraph:
			"At the heart of downstream activities, our chemical alchemy exerts a profound influence on distribution and retail. Guided by meticulous chemical dosing, our fuel dispensers optimize combustion efficiency, ensuring complete energy utilization. Simultaneously, our point-of-sale systems facilitate transactions with precision, overseeing the seamless exchange of value. This choreographed synergy ensures the seamless delivery of enhanced products within the supply chain.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/2059/2059430.png?uid=R113815869&ga=GA1.1.782505387.1692784381",
		imageName: "Chemical Fusion icon",
	},
];

export { OgServicesData };