import Footer from "../../components/Navigation/Footer";
import HeaderPlain from "../../components/Navigation/HeaderPlain";
import HeaderVideo from "../../components/Navigation/HeaderVideo";
import HealthDetails from "../divisions/Healthdetail";
import Healthhero from "./HealthHero";
import Hero from "./Hero";
import Intro from "./WhoAreWe";

export default function Healthcare() {
  return (
    <>
      <HeaderPlain />
      <main>
        <div className="bg-bottom bg-no-repeat bg-cover pb-8">
          <Healthhero />

          <HealthDetails />
          {/* <Vision /> */}
          {/* <Mission/> */}
          {/* <OurServices/> */}

          {/* <Expertise /> */}

          {/* <MeettheTeam /> */}
          {/* <OurProducts/> */}
        </div>
        {/* <Contact /> */}
      </main>
      <Footer />
    </>
  );
}
