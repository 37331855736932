import HeaderPlain from "../components/Navigation/HeaderPlain";
import ProfileCard from "../components/OurTeam/ProfileCard";
import Footer from "../components/Navigation/Footer";

import { Carousel } from "flowbite-react";
import { dataTeamMembers } from "../data/TeamMembers";
import { products } from "../data/products";

export default function OurTeam() {
  return (
    <>
      <HeaderPlain />
      <main className="mt-48 pb-8 lg:pb-0 min-h-justBody bg-light-768profile md:bg-light-1024profile lg:bg-light-1440profile dark:bg-dark-768profile dark:md:bg-dark-1024profile dark:lg:bg-dark-1440profile bg-bottom">
        <div
          className="flex justify-center items-center flex-col relative"
          style={{ background: "linear-gradient(white,#EDC978)" }}
        >
          <img
            src="https://img.freepik.com/free-vector/men-with-shopping-car-business-coins_24877-53519.jpg?w=826&t=st=1708166266~exp=1708166866~hmac=5335479c0b1489c218f996d32b67d10b946e40832c3efa7e370bd339fb1417e9"
            style={{ width: "10%" }}
          />
          <h1
            className="text-4xl md:text-5xl text-darkBlue font-bold border-b-4 w-fit border-lightBlue md:mx-auto dark:text-white"
            style={{
              borderColor: "#EDC978",
              marginBottom: "2%",
              fontSize: "2rem",
            }}
          >
            OUR PRODUCTS
          </h1>
          <div className="h-232 457wide:h-240 sm:h-292 md:h-128 px-8 container max-w-screen-xs  mx-auto mt-8 md:mt-9 mb-10 md:mb-9">
            <Carousel
              slide={false}
              indicators={false}
              leftControl="<"
              rightControl=">"
            >
              {products.map((item) => {
                return ProfileCard(item);
              })}
            </Carousel>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
