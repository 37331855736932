import { useState } from "react";
import Fade from 'react-reveal/Fade';
import BurgerMenu from '../../assets/images/burger.svg';
import Logo from "../../assets/images/logo/amexLogo.png";
import { useNavigate } from "react-router-dom";

export default function HeaderPlain() {
	const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <header className='relative text-white p-8 top-0 z-1 -mt-40 lg:px-10'>
      <nav className='fixed bg-darkBlue top-0 left-0 right-0 p-6 lg:px-10 xl:px-20 flex justify-between items-center' 
      //  style={{backgroundColor:"rgba(255,255,255,0.5)"}}
      style={{backgroundColor:"transparent"}}
      >
        <Fade top>
          <h2 className='font-Overpass font-bold text-xl mt-1 lg:text-2xl xl:text-3xl'>
            <a href="/" title="Go to Home page">
            <img 
							src={Logo} 
							className="w-[5.5rem]  md:w-[10rem] lg:w-[10rem] xl:w-[8rem]"
							// style={{width:"15%"}}
							/>
            </a>
          </h2>
        </Fade>
        {/* Mobile Menu */}
        <div className="flex lg:hidden">
          <Fade top>
            <img 
              className='h-10 w-10' 
              onClick={() => setIsNavOpen((prev) => !prev)}
              src={BurgerMenu}
              alt='menu'
            ></img>
          </Fade>
          <div className={isNavOpen ? "showNav" : "hideNav"}>
            <div 
              className="absolute top-0 right-0 p-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                  className="h-8 w-8 text-white fill-none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <Fade cascade>
              <ul className='nav-items-mobile'>
                <li><a href='' title='Home' style={{color:"#383839", fontFamily:"'Poppins', sans-serif", fontWeight:"600"}}  onClick={navigate("/")}>Home</a></li>
                <li><a href='#about' title='About Us' style={{color:"#383839", fontFamily:"'Poppins', sans-serif", fontWeight:"600"}}  onClick={navigate("/#about")}>About</a></li>
                {/* <li><a href='http://Www.Amex-Intl.com' title='Our Work'>Work</a></li> */}
                <li><a href='#contact-us' title='Contact Us' style={{color:"#383839", fontFamily:"'Poppins', sans-serif", fontWeight:"600"}}  onClick={navigate("/#contact-us")}>Contact</a></li>
              </ul>
            </Fade>
          </div>
        </div>

        {/* Desktop Menu */}
        <Fade cascade>
          <ul className='nav-items-desktop'>
            {/* <li><a className="transition duration-200 hover:text-sky-300 hover:underline" href='' title='Home' style={{color:"#383839", fontFamily:"'Poppins', sans-serif", fontWeight:"600"}} onClick={navigate("/")}>Home</a></li> */}
            {/* <li><a className="transition duration-200 hover:text-sky-300 hover:underline" href='#about' title='About Us' style={{color:"#383839", fontFamily:"'Poppins', sans-serif", fontWeight:"600"}} onClick={navigate("/#about")}>About</a></li> */}
            {/* <li><a className="transition duration-200 hover:text-sky-300 hover:underline" href='https://jessicamlee.github.io/AMEX/' title='Our Work'>Work</a></li> */}
            <li><a className="transition duration-200 hover:text-sky-300 hover:underline" href='/' title='Contact Us' style={{color:"#383839", fontFamily:"'Poppins', sans-serif", fontWeight:"600", color:"#EDC978"}} onClick={navigate("/")}>Home</a></li>
          </ul>
        </Fade>
      </nav>
    </header>
  )
}