import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import Home from "./pages/Home";
// import Who from './sections/Home/WhoAreWe';
import OurTeam from "./pages/OurTeam";
import Expertise from "./sections/Home/Expertise";
import Healthcare from "./sections/Home/Healthcare";
import OilGas from "./sections/divisions/oilAndGas/oilGas";
import MeetTeam from "./sections/Team/MeetTeam";
import Intro from "./sections/Home/WhoAreWe";
import HeroIntro from "./sections/Home/HeroIntro";
import ContactPage from "./sections/Home/ContactPage";

function App() {
  return (
    <>
      <HashRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productList" element={<OurTeam />} />
          <Route path="/divisions" element={<Expertise />} />
          <Route path="/healthcare" element={<Healthcare />} />
          <Route path="/oilAndGas" element={<OilGas />} />
          <Route path="/team" element={<MeetTeam />} />
          <Route path="/about" element={<HeroIntro />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* <Route path="/about" element={<Who />} /> */}
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
