import ExpertiseCard from "../../components/Home/ExpertiseCard";
import Fade from "react-reveal/Fade";
import { medExcellence } from "../../data/medExcellence";
import ExcellenceCard from "./ExcellenceCard";
import { MedEngSolData } from "../../data/medEngSolData";
import MedEngCard from "./MedEngCard";

export default function MedEngSol() {
  return (
    <Fade>
      <div className="grid grid-cols-1 gap-16 w-md px-6 lg:px-10 mx-auto max-w-screen-xl">
        <h2
          className="text-4xl text-darkBlue font-bold w-fit"
          style={{ color: "#EDC978", marginLeft: "auto", marginRight: "auto" }}
        >
          Medical Engineering Solutions
        </h2>

        <div
          className="container px-2 md:pl-20 md:mt-8 lg:pl-0 md:grid block grid-cols-3 max-w-3xl mx-auto gap-10"
          style={{ maxWidth: "84rem" }}
        >
          {MedEngSolData.map((item) => {
            return MedEngCard(item);
          })}
        </div>
      </div>
    </Fade>
  );
}
