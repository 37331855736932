import React from "react";
import Fade from "react-reveal/Fade";
import CMJSHfull from "../../assets/images/homepage/who11.jpg";

export default function Intro() {
  return (
    <div
      className="grid grid-cols-1 px-6 mt-50 relative mb-44 md:mt-[21rem] lg:px-10 lg:grid-cols-2 lg:mt-56 xl:mt-40 xl:mb-64 xl:max-w-screen-xl xl:mx-auto"
      id="#about"
    >
      <Fade>
        <img
          className="w-[10.5rem] -top-[17rem] right-0 left-0 mx-auto md:w-[10rem] md:-top-[29.3rem] lg:max-w-none lg:w-[10rem] lg:-top-[27.5rem] lg:-right-[63rem] xl:w-[26rem] xl:-top-[18rem] xl:-right-[48rem]"
          src={CMJSHfull}
          alt="our work"
          style={{ borderRadius: "20px" }}
        ></img>
      </Fade>
      <Fade cascade>
        <div
          className="flex flex-col gap-20 max-w-md mx-auto lg:mx-0"
          style={{ gap: "1.5rem" }}
        >
          <h2
            className="text-4xl text-darkBlue font-bold border-b-4 w-fit border-lightBlue"
            style={{
              borderColor: "#B7894F",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "600",
              color: "#B7894F",
            }}
          >
            WHO WE ARE ?
          </h2>
          <p
            className="text-sm font-light leading-6 md:pr-12 lg:pl-20 lg:pr-0"
            style={{
              width: "150%",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "500",
              color: "black",
            }}
          >
            Welcome to AMEX INTERNATIONAL, a trailblazing force in the GCC's
            healthcare, Oil & Gas, and Facility Management sectors. Since our
            inception in 2014, we have emerged as a pioneering service provider,
            setting new standards of innovation, compassion, and excellence
            across Qatar, Saudi Arabia, and the Sultanate of Oman. At AMEX, we
            believe in shaping a brighter future for healthcare, where
            cutting-edge technology, dedicated professionals, and unwavering
            commitment converge to redefine the landscape of well-being. Our
            journey is defined by a relentless pursuit of excellence, as we
            stand proudly at the forefront of healthcare advancement. In the
            realm of Oil & Gas, AMEX INTERNATIONAL stands as a steadfast
            partner, offering a comprehensive spectrum of services that drive
            efficiency, safety, and sustainable growth. Our expertise in this
            critical sector empowers us to navigate complexities and deliver
            solutions that power progress. Moreover, our influence extends to
            the Engineering and Facility Management domains, where we
            orchestrate seamless operations and elevate infrastructure
            management. With an unwavering focus on quality and reliability, we
            ensure that your facilities operate at their peak, allowing you to
            focus on your core objectives. At AMEX, our legacy is defined by a
            passion for innovation, a dedication to compassion, and a commitment
            to excellence. We invite you to explore our world of possibilities
            as we continue to redefine the boundaries of success in healthcare,
            Oil & Gas, and Facility Management. Join us on this journey towards
            a future where progress knows no limits.
          </p>
          {/* <button className="btn-sm lg:ml-20 transition duration-200 hover:bg-sky-300">
						View Our Work
					</button> */}
        </div>
      </Fade>
    </div>
  );
}
