import React from "react";
import Fade from "react-reveal/Fade";
import CMJSHfull from "../../assets/images/homepage/amexvision.png";

export default function Vision() {
	return (
		<div className="grid grid-cols-1 px-6 mt-50 relative mb-44 md:mt-[21rem] lg:px-10 lg:grid-cols-2 lg:mt-56 xl:mt-40 xl:mb-64 xl:max-w-screen-xl xl:mx-auto" style={{backgroundColor:"#EDC978", padding:"8%", borderRadius:"30px", marginBottom:"3rem"}}>
			{/* <Fade>
				<img
					className="absolute w-[21.5rem] -top-[17rem] right-0 left-0 mx-auto md:w-[42rem] md:-top-[29.3rem] lg:max-w-none lg:w-[84rem] lg:-top-[27.5rem] lg:-right-[63rem] xl:w-[86rem] xl:-top-[27rem] xl:-right-[58rem]"
					src={CMJSHfull}
					alt="our work"
				></img>
			</Fade> */}
			<Fade cascade>
				<div className="flex flex-col gap-10 max-w-md mx-auto lg:mx-0">
					{/* <h2 className="text-4xl text-darkBlue font-bold border-b-4 w-fit border-lightBlue" style={{borderColor:"#EDC978", fontFamily:"'Poppins', sans-serif", fontWeight:"500"}}>
						OUR <span style={{fontFamily:"'Poppins', sans-serif", fontWeight:"700"}}>VISION</span>
					</h2>
					<p className="text-sm font-light leading-6 md:pr-12 lg:pl-20 lg:pr-0" style={{width:"130%", paddingLeft:0, fontFamily:"'Poppins', sans-serif", fontWeight:"500"}}>
					Our mission at AMEX INTERNATIONAL is to spearhead transformation in the Healthcare, Oil & Gas, Engineering, and Facility Management sectors within the GCC and beyond. By consistently delivering innovative solutions, fostering enduring partnerships, and upholding unwavering excellence, we strive to establish ourselves as the pinnacle of industry leadership in the region. With our eyes set on global expansion, we are committed to laying the groundwork for a future where our impact, quality, and influence transcend borders, enriching industries and communities worldwide.

					</p> */}
					<Fade>
				<img
					className=" w-[21.5rem] -top-[17rem] right-0 left-0 mx-auto md:w-[42rem] md:-top-[29.3rem] lg:max-w-none lg:w-[84rem] lg:-top-[27.5rem] lg:-right-[63rem] xl:w-[35rem] xl:-top-[27rem] xl:-right-[58rem]"
					src={CMJSHfull}
					alt="our work"
				></img>
				{/* <h2 className="text-4xl text-darkBlue font-bold border-b-4 w-fit border-lightBlue" style={{borderColor:"#EDC978", fontFamily:"'Poppins', sans-serif", fontWeight:"500"}}>
				Healthcare
					</h2> */}
			</Fade>
					{/* <button className="btn-sm lg:ml-20 transition duration-200 hover:bg-sky-300">
						View Our Work
					</button> */}
				</div>
			</Fade>
			{/* <Fade>
				<img
					className="absolute w-[21.5rem] -top-[17rem] right-0 left-0 mx-auto md:w-[42rem] md:-top-[29.3rem] lg:max-w-none lg:w-[84rem] lg:-top-[27.5rem] lg:-right-[63rem] xl:w-[86rem] xl:-top-[27rem] xl:-right-[58rem]"
					src={CMJSHfull}
					alt="our work"
				></img>
			</Fade> */}
            <Fade cascade>
				<div className="flex flex-col gap-10 max-w-md mx-auto lg:mx-0"  style={{gap:"0.5rem", paddingTop:"2%"}}>
					<h2 className="text-4xl text-darkBlue font-bold border-b-4 w-fit border-lightBlue" style={{borderColor:"#EDC978", fontFamily:"'Poppins', sans-serif", fontWeight:"500"}}>
						OUR <span style={{fontFamily:"'Poppins', sans-serif", fontWeight:"700"}}>VISION</span>
					</h2>
					<p className="text-sm font-light leading-6 md:pr-12 lg:pl-20 lg:pr-0" style={{width:"130%", paddingLeft:0, fontFamily:"'Poppins', sans-serif", fontWeight:"500"}}>
					Our mission at AMEX INTERNATIONAL is to spearhead transformation in the Healthcare, Oil & Gas, Engineering, and Facility Management sectors within the GCC and beyond. By consistently delivering innovative solutions, fostering enduring partnerships, and upholding unwavering excellence, we strive to establish ourselves as the pinnacle of industry leadership in the region. With our eyes set on global expansion, we are committed to laying the groundwork for a future where our impact, quality, and influence transcend borders, enriching industries and communities worldwide.

					</p>
					{/* <button className="btn-sm lg:ml-20 transition duration-200 hover:bg-sky-300">
						View Our Work
					</button> */}
				</div>
			</Fade>
		</div>
	);
}
