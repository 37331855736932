import TeamMemberCard from "../../components/Home/TeamMemberCard";
import Footer from "../../components/Navigation/Footer";
import HeaderPlain from "../../components/Navigation/HeaderPlain";
import { dataTeamMembers } from "../../data/TeamMembers";
import Fade from "react-reveal/Fade";

export default function MeetTeam() {
  return (
    <div>
      <HeaderPlain />
      <div
        className="grid gap-16 section-spacing mx-auto max-w-screen-xl"
        style={{ marginTop: "12rem" }}
      >
        <Fade>
          <h2
            className="text-4xl text-darkBlue font-bold w-fit border-lightBlue md:mx-auto dark:text-white"
            style={{ color: "#EDC978", borderColor: "#B7894F" }}
          >
            OUR TEAM PLAYERS
          </h2>
        </Fade>
        <ul
          className="flex flex-wrap gap-x-6 gap-y-10 justify-center md:px-16 md:gap-x-12 md:gap-y-14"
          style={{ columnGap: "11rem" }}
        >
          {dataTeamMembers.map((item) => {
            return TeamMemberCard(item);
          })}
        </ul>
      </div>
      <Footer />
    </div>
  );
}
