import HeaderVideo from "../components/Navigation/HeaderVideo";
import Hero from "../sections/Home/Hero";
import WhoAreWe from "../sections/Home/WhoAreWe";
import Vision from "../sections/Home/Vision";
import Mission from "../sections/Home/Mission";
import Expertise from "../sections/Home/Expertise";
import MeettheTeam from "../sections/Home/MeettheTeam";
import Contact from "../sections/Home/Contact";
import Footer from "../components/Navigation/Footer";
import OurServices from "../sections/Home/Services";
import OurProducts from "../sections/Home/Products";
import Ceo from "../sections/Home/Ceo";

export default function Home() {
	return (
		<>
			<HeaderVideo />
			<main  style={{backgroundColor:"white"}}>
				<div className="bg-bottom bg-no-repeat bg-cover pb-8">
					
					<Hero />

					<WhoAreWe />
					<Vision />
					<Mission/>
					<Ceo/>
					{/* <OurServices/> */}

					{/* <Expertise /> */}


					{/* <MeettheTeam /> */}
					{/* <OurProducts/> */}
				</div>
				<Contact />
			</main>
			<Footer />
		</>
	);
}
