// import React from "react";
// import Fade from "react-reveal/Fade";
// import CMJSHfull from "../../assets/images/homepage/who11.jpg";
// import HealthExcellence from "./HealthExcellence";
// import MedEngSol from "./medEngSol";

import HealthExcellence from "../HealthExcellence";
import MedEngSol from "../medEngSol";
import OgDescription from "./OgDescription";
import OgServices from "./OgServices";

export default function OgDetail() {
  return (
    <div>
      <div
        className="grid grid-cols-1 px-6 mt-50 relative mb-44 md:mt-[21rem] lg:px-10 lg:grid-cols-2 lg:mt-56 xl:mt-40 xl:mb-64 xl:max-w-screen-xl xl:mx-auto"
        id="about"
      >
        {/* <Fade>
				<img
					className="absolute w-[10.5rem] -top-[17rem] right-0 left-0 mx-auto md:w-[10rem] md:-top-[29.3rem] lg:max-w-none lg:w-[10rem] lg:-top-[27.5rem] lg:-right-[63rem] xl:w-[20rem] xl:-top-[18rem] xl:-right-[48rem]"
					src={CMJSHfull}
					alt="our work"
					style={{borderRadius:"20px"}}
				></img>
			</Fade> */}
        {/* <Fade cascade>
				<div className="flex flex-col gap-10 max-w-md mx-auto lg:mx-0">
                    <img
					className=" w-[21.5rem] -top-[17rem] right-0 left-0 mx-auto md:w-[42rem] md:-top-[29.3rem] lg:max-w-none lg:w-[84rem] lg:-top-[27.5rem] lg:-right-[63rem] xl:w-[20rem] xl:-top-[27rem] xl:-right-[58rem]"
					src={CMJSHfull}
					alt="our work"
				></img>
				</div>
			</Fade> */}
        {/* <Fade cascade>
				<div className="flex flex-col gap-20 max-w-md mx-auto lg:mx-0" style={{gap:"1.5rem"}}>
					<p className="absolute font-bold text-4xl leading-8 mt-12 md:text-2xl lg:text-1xl" style={{fontFamily:"'Poppins', sans-serif", fontWeight:"500", color:"#EDC978", marginTop:'0'}}>
					"We believe in shaping a brighter future for healthcare, where
cutting-edge technology, dedicated professionals, and unwavering
commitment converge to redefine the landscape of well-being. Our
journey is defined by a relentless pursuit of excellence, as we stand
proudly at the forefront of healthcare advancement."

					</p>
				</div>
			</Fade> */}
        {/* <HealthExcellence/> */}
      </div>
      {/* <div style={{ backgroundColor: "#EDC978", padding: "5%" }}> */}
      {/* <OgDescription/> */}
      {/* </div> */}
      {/* <div style={{backgroundColor:"#EDC978",padding:"5%"}}>
		 <HealthExcellence/>
		 </div> */}
      <div style={{ padding: "5%" }}>
        {/* <MedEngSol/> */}
        <OgServices />
      </div>
    </div>
  );
}
