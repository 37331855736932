const medExcellence = [
	{
		id: 1,
		title: "Discover the Future of Healthcare Facilities",
		paragraph:
			"Experience seamless project management from concept to reality. Our expert team transforms your vision into a state-of-the-art medical facility that's ready to serve.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/33/33777.png?uid=P113815869&ga=GA1.1.782505387.1692784381",
		imageName: "facility icon",
	},
	{
		id: 2,
		title: "Comprehensive Services",
		paragraph:
			"From design and construction to equipment procurement and regulatory compliance, we handle every detail. Focus on patient care while we manage the rest.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/483/483454.png?ga=GA1.1.782505387.1692784381",
		imageName: "health icon",
	},
	{
		id: 3,
		title: "Timely Delivery",
		paragraph:
			"Your time is valuable. We ensure your facility is up and running on schedule, without compromise on quality.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/45/45663.png?ga=GA1.1.782505387.1692784381",
		imageName: "timely icon",
	},
	{
		id: 4,
		title: "Innovative Solutions",
		paragraph:
			"Harness cutting-edge medical engineering for improved diagnostics, treatment, and patient outcomes.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/566/566410.png?ga=GA1.1.782505387.1692784381",
		imageName: "Solutions icon",
	},
    {
		id: 5,
		title: "Customization & Integration",
		paragraph:
			"Tailored solutions that seamlessly integrate with your existing systems, enhancing efficiency and performance.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/1071/1071510.png?ga=GA1.1.782505387.1692784381",
		imageName: "Integration icon",
	},
    {
		id: 6,
		title: "Ongoing Support",
		paragraph:
			"Our commitment doesn't end at project completion. We're here for maintenance, upgrades, and future expansions.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/1441/1441147.png?ga=GA1.1.782505387.1692784381",
		imageName: "Support icon",
	},
    {
		id: 7,
		title: "Global Standards",
		paragraph:
			"Stay compliant with industry regulations and elevate your facility to meet international healthcare standards.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/52/52349.png?ga=GA1.1.782505387.1692784381",
		imageName: "Global icon",
	},
    {
		id: 8,
		title: "Partnership & Trust",
		paragraph:
			"Join hands with us for a brighter, healthier tomorrow. Let's shape the future of healthcare together.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/3585/3585639.png?ga=GA1.1.782505387.1692784381",
		imageName: "Partnership icon",
	},
];

export { medExcellence };