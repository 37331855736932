import React from "react";
import Fade from "react-reveal/Fade";
// import HeroVideo from '../../assets/videos/videoBg.mp4';
import HeroImg from "../../assets/images/homepage/heldet.jpg";

export default function Healthhero() {
  return (
    <div className="bg-darkBlue text-white h-[36rem] flex justify-center items-center flex-col relative lg:h-[44rem]">
      {/* <video className='hidden md:block object-cover h-full w-full brightness-50' src={HeroVideo} autoPlay muted loop /> */}
      <img
        className="hidden md:block object-cover h-full w-full brightness-50"
        src={HeroImg}
      />
      <Fade cascade bottom>
        <h1 className="absolute font-bold text-4xl leading-8 mt-12 md:text-5xl lg:text-7xl">
          <span
            className="block"
            style={{
              color: "#B7894F",
              fontSize: "30%",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "600",
            }}
          ></span>
          <span
            className="block"
            style={{
              color: "#EDC978",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "600",
            }}
          >
            HEALTHCARE
          </span>
        </h1>
        {/* <Fade cascade> */}
        <p
          className="absolute font-bold text-4xl leading-8 mt-12 md:text-2xl lg:text-1xl"
          style={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "300",
            color: "white",
            marginTop: "23%",
            width: "65%",
            fontSize: "1.1rem",
            lineHeight: "1.6rem",
          }}
        >
          "We believe in shaping a brighter future for healthcare, where
          cutting-edge technology, dedicated professionals, and unwavering
          commitment converge to redefine the landscape of well-being. Our
          journey is defined by a relentless pursuit of excellence, as we stand
          proudly at the forefront of healthcare advancement."
        </p>
        {/* </Fade> */}
        {/* <Fade cascade> */}
        <div
          className="flex flex-col gap-20 max-w-md mx-auto lg:mx-0"
          style={{ gap: "1.5rem" }}
        >
          {/* <h2 className="text-4xl text-darkBlue font-bold border-b-4 w-fit border-lightBlue" style={{borderColor:"#EDC978", fontFamily:"'Poppins', sans-serif", fontWeight:"600", color:"white"}}>
						Healthcare``
					</h2> */}
          {/* <p
              className="absolute font-bold text-4xl leading-8 mt-12 md:text-2xl lg:text-1xl"
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "500",
                color: "#EDC978",
                marginTop: "0",
              }}
            >
              "We believe in shaping a brighter future for healthcare, where
              cutting-edge technology, dedicated professionals, and unwavering
              commitment converge to redefine the landscape of well-being. Our
              journey is defined by a relentless pursuit of excellence, as we
              stand proudly at the forefront of healthcare advancement."
            </p> */}
          {/* <button className="btn-sm lg:ml-20 transition duration-200 hover:bg-sky-300">
						View Our Work
					</button> */}
        </div>
        {/* </Fade> */}
      </Fade>
    </div>
  );
}
