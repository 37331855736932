import React from "react";
import Fade from "react-reveal/Fade";
// import HeroVideo from '../../assets/videos/videoBg.mp4';
// import HeroImg from '../../assets/images/homepage/heldet.jpg';
import HeroImg from "../../../assets/images/homepage/ogheroimg.jpg";
import "./style.css";

export default function OgHero() {
  return (
    <div
      className="bg-darkBlue text-white h-[36rem] flex justify-center items-center flex-col relative mb-52 lg:h-[44rem]"
      style={{ marginBottom: "-27rem" }}
    >
      {/* <video className='hidden md:block object-cover h-full w-full brightness-50' src={HeroVideo} autoPlay muted loop /> */}
      <img
        className="hidden md:block object-cover h-full w-full brightness-50"
        src={HeroImg}
      />
      <Fade cascade bottom>
        <h1 className="absolute font-bold text-4xl leading-8 mt-12 md:text-5xl lg:text-7xl">
          <span
            className="block"
            style={{
              color: "#B7894F",
              fontSize: "30%",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "600",
            }}
          ></span>
          <span
            className="block"
            style={{
              color: "#EDC978",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "600",
            }}
          >
            OIL AND GAS DIVISION
          </span>
        </h1>
        {/* <p
          className="absolute font-bold text-4xl leading-8 mt-12 md:text-2xl lg:text-1xl ogparagraph"
          style={{
            width: "82%",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "300",
            color: "white",
            marginTop: "34%",
            fontSize: "1rem",
            lineHeight: "1.5rem",
          }}
        >
          Welcome to our Oil and Gas Department! Step into the refined domain of
          our illustrious Oil and Gas Equipment Division, our realm is
          meticulously tailored to provide you with a curated selection of
          cutting-edge equipment, premium supplies, and sophisticated chemical
          dosing solutions, all underscored by our comprehensive AMC (Annual
          Maintenance Contract) offerings. With our headquarters situated in
          Qatar and a strong operational presence in the Kingdom of Saudi Arabia
          and the Sultanate of Oman, beckoning you to embark on an extraordinary
          voyage owards innovation and partnership.
        </p> */}
      </Fade>
    </div>
  );
}
