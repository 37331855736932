import Footer from "../../../components/Navigation/Footer";
import HeaderPlain from "../../../components/Navigation/HeaderPlain";
import Healthhero from "../../Home/HealthHero";
import HealthDetails from "../Healthdetail";
import OgDetail from "./OgDetail";
import OgHero from "./OgHero";

export default function OilGas() {
  return (
    <>
      <HeaderPlain />
      <main>
        <div className="bg-bottom bg-no-repeat bg-cover pb-8">
          <OgHero />

          <OgDetail />
          {/* <Vision /> */}
          {/* <Mission/> */}
          {/* <OurServices/> */}

          {/* <Expertise /> */}

          {/* <MeettheTeam /> */}
          {/* <OurProducts/> */}
        </div>
        {/* <Contact /> */}
      </main>
      <Footer />
    </>
  );
}
