import Fade from 'react-reveal/Fade';
import { useNavigate } from 'react-router-dom';

export default function ExpertiseCard({
	id,
	title,
	paragraph,
	imagePath,
	imageName,
}) {

	const navigate = useNavigate();

	function navigatePage(e) {
		if(e==="Engineering and facility management"){
			navigate("/efm")
		}else if(e==="Healthcare"){
			navigate("/healthcare")
		}else{
			navigate("/oilAndGas")
		}
	}
	return (
		<Fade cascade>
			<div
				key={id}
				className="mb-10 text-darkBlue font-bold w-fit sm:pr-4 md:pr-12 lg:pl-20 lg:pr-0"
				style={{backgroundColor:"#EDC978",padding:"20%",borderRadius:"10px"}}
			>
				<img className="hidden md:block" src={imagePath} alt={imageName} style={{width:"20%"}}/>
				<h3 className="font-bold mb-8 mt-4" style={{width:"130%", paddingLeft:0, fontFamily:"'Poppins', sans-serif", fontWeight:"700", color:"black"}}>{title}</h3>
				<p className="text-sm font-light leading-6" style={{width:"110%", paddingLeft:0, fontFamily:"'Poppins', sans-serif", fontWeight:"500", color:"black"}}>{paragraph}</p>
				{(title === "Healthcare" || title === "Oil and gas") &&
				<button
					className="btn-full transition duration-200 hover:bg-sky-300 "
					title="view more details"
					onClick={()=>navigatePage(title)}
				>
					View
				</button>
				}
			</div>
		</Fade>
	);
}
