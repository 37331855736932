const MedEngSolData = [
	{
		id: 1,
		title: "Innovate Care, Elevate Health",
		paragraph:
			"Revolutionize patient outcomes with advanced medical technology and custom solutions",
		imagePath: "https://cdn-icons-png.flaticon.com/512/4185/4185733.png?ga=GA1.1.782505387.1692784381",
		imageName: "innovative icon",
	},
	{
		id: 2,
		title: "Design to Deployment",
		paragraph:
			"From concept to operation, we engineer tailored medical solutions for seamless integration.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/4337/4337297.png?ga=GA1.1.782505387.1692784381",
		imageName: "deployment icon",
	},
	{
		id: 3,
		title: "Tech-Driven Care",
		paragraph:
			"Harness the power of connectivity and precision for accurate diagnostics and treatment.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/2519/2519444.png?ga=GA1.1.782505387.1692784381",
		imageName: "techdriven icon",
	},
	{
		id: 4,
		title: "Reliability Matters",
		paragraph:
			"Maintain and upgrade your equipment for consistent performance and patient safety.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/3967/3967544.png?ga=GA1.1.782505387.1692784381",
		imageName: "reliability icon",
	},
    {
		id: 5,
		title: "Expert Training",
		paragraph:
			"Empower your team with in-depth training on equipment use and maintenance",
		imagePath: "https://cdn-icons-png.flaticon.com/512/1064/1064587.png?ga=GA1.1.782505387.1692784381",
		imageName: "training icon",
	},
    {
		id: 6,
		title: "Beyond Boundaries",
		paragraph:
			"Explore new horizons in medical science with our forward-thinking engineering expertise.",
		imagePath: "https://cdn-icons-png.flaticon.com/512/814/814513.png?ga=GA1.1.782505387.1692784381",
		imageName: "boundary icon",
	},
];

export { MedEngSolData };