import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
// import gg from '../../assets'
import "./style.css";

export default function TeamMemberCard({
  id,
  name,
  role,
  paragraph,
  imagePath,
  imageName,
}) {
  const navigate = useNavigate();

  function navigateToProfile() {
    navigate("/productList");
  }

  return (
    <Fade cascade>
      <li
        key={id}
        className="375wide:w-46pct md:w-28pct xl:w-22pct xl:max-w-22pct zoom"
      >
        <img
          className="rounded w-full w-[5.5rem]  md:w-[10rem] lg:w-[10rem] xl:w-[25rem]"
          src={imagePath}
          alt={imageName}
          style={{
            margin: "auto",
            maxWidth: "30rem",
            borderRadius: "19%",
            backgroundColor: "rgb(237, 201, 120)",
          }}
        />
        <h3
          className="text-darkBlue text-base lg:text-2xl font-bold leading-5 mt-3 dark:text-white"
          style={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "300",
            color: "#B7894F",
            marginLeft: "1rem",
          }}
        >
          {name}
        </h3>
        <p
          className="text-roleDark text-xs md:text-sm lg:text-base font-light leading-4 mt-2 lg:mt-0 lg:font-regular dark:text-roleLight"
          style={{ color: "#EDC978", marginLeft: "1rem" }}
        >
          {role}
        </p>
        {/* <p className="text-darkBlue text-xs font-light mt-2 md:text-sm xl:font-regular dark:text-white">
					{paragraph}
				</p> */}
        {/* <button
					className="btn-full transition duration-200 hover:bg-sky-300 "
					title="Go to Profile page"
					onClick={navigateToProfile}
				>
					View
				</button> */}
      </li>
    </Fade>
  );
}
